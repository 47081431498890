import { Stack, Typography } from "@mui/material";
import { EnterpriseMode } from "Api/Api";
import { CommissionDialogForm } from "Components/Settings/Commission/CommisionDialogForm";
import { BlDefaultTooltip } from "Components/Shared/BlTooltipDefault";
import { BlButton } from "Components/Shared/Buttons/BlButton";
import { BlDataTable } from "Components/Shared/DataTable/BlDataTable";
import { StyledValidationText } from "Components/Shared/FormStyles";
import { StyledFlex } from "Components/Shared/StyledComponents";
import { useAppDispatch } from "Hooks/State/useAppDispatch";
import { getEnterpriseCommissionSettingsAsync } from "State/Enterprises/Commission/CommissionSettingsSlice";
import {
  closeDialog,
  openDialog,
} from "State/Enterprises/Commission/CreateCommissionSettingsSlice";
import { useAppSelector } from "State/Store";
import { Resources, useResource } from "Translations/Resources";
import { formatDate } from "Utils/DateUtils";
import { useEffect } from "react";
import styled from "styled-components";

const ActualButton = styled(BlButton)`
  height: 32px;
  border-color: ${props => props.theme.colors.border};
  color: ${props => props.theme.palette.common.black};
  pointer-events: none;
`;

export const CommissionList: React.FunctionComponent = _ => {
  const dispatch = useAppDispatch();
  const { t } = useResource();
  const { data } = useAppSelector(state => state.enterprise.commissionSettings);
  const { isDialogOpened } = useAppSelector(
    state => state.enterprise.createCommissionSettings,
  );
  const enterpriseMode = useAppSelector(
    x => x.enterprise.basicSettings.settings.data?.mode,
  );

  useEffect(() => {
    dispatch(getEnterpriseCommissionSettingsAsync.request());
  }, [dispatch]);

  return (
    <>
      <Stack flexDirection="row" justifyContent="space-between">
        <div>
          <StyledFlex $alignItems="center" $gap={0.5}>
            <Typography variant="h2">
              {t(Resources.Settings.Commission.Title)}
            </Typography>
            <BlDefaultTooltip
              title={t(Resources.Settings.Commission.Tooltip)}
            />
          </StyledFlex>
          <Typography variant="subtitle2" fontStyle={"normal"} marginBottom={1}>
            {t(Resources.Settings.Commission.Description)}
          </Typography>

          {enterpriseMode === EnterpriseMode.Free && (
            <StyledValidationText $fontSize="14px">
              {t(Resources.Settings.Commission.EnterpriseModeFree)}
            </StyledValidationText>
          )}
        </div>

        <div>
          <BlButton color="primary" onClick={() => dispatch(openDialog())}>
            {t(Resources.Commission.Create.OpenDialogButton)}
          </BlButton>

          {isDialogOpened && (
            <CommissionDialogForm
              lastCommissionSettings={
                data?.items[0] ?? {
                  advisorCommissionRate: 0,
                  enterpriseCommissionRate: 0,
                }
              }
              onClosed={() => dispatch(closeDialog())}
            />
          )}
        </div>
      </Stack>

      <BlDataTable
        totalCount={data?.totalCount}
        rows={data?.items.map(e => ({ data: e })) ?? []}
        rowEntityID="enterpriseCommissionSettingID"
        isPaginationDisabled={true}
        getHeaderCell={column => {
          return <div>{column.label ?? column.id}</div>;
        }}
        columns={[
          { id: "dateFrom", label: "Období platnosti" },
          { id: "enterpriseCommissionRate", label: "Podíl pro HSP" },
          { id: "advisorCommissionRate", label: "Podíl pro poradce" },
          { id: "isActive", label: "" },
        ]}
        getCell={(column, row) => {
          switch (column) {
            case "dateFrom":
              return (
                <span>
                  {formatDate(row.dateFrom)} -{" "}
                  {!!row.dateTo ? formatDate(row.dateTo) : ""}
                </span>
              );

            case "enterpriseCommissionRate":
            case "advisorCommissionRate":
              return <span>{row[column] * 100}%</span>;

            case "isActive":
              return row.isActive ? (
                <ActualButton color="secondary">
                  {t(Resources.Common.Actual)}
                </ActualButton>
              ) : (
                <></>
              );

            default:
              const value = (row as any)[column as any] ?? "";

              return <div>{value}</div>;
          }
        }}
      />
    </>
  );
};
